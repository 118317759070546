import { EXPORT_CONNECTORS_MAP, CONNECTORS_MAP } from './connectors'
import { STEP_SUBTYPES } from './steps'

export const SPLITIO_FEATURE_FLAGS = {
  CONNECTORS: {
    [CONNECTORS_MAP.BIGQUERY.key]: 'tpin_bigquery',
    [CONNECTORS_MAP.DATABRICKS.key]: 'tpin_databricks',
    [CONNECTORS_MAP.DROPBOX.key]: 'tpin_dropbox',
    [CONNECTORS_MAP.FACEBOOK_ADS.key]: 'tpin_facebook_ads',
    [CONNECTORS_MAP.GOOGLE_ADS.key]: 'tpin_ggl_ads',
    [CONNECTORS_MAP.GOOGLE_ANALYTICS.key]: 'tpin_google_analytics',
    [CONNECTORS_MAP.GOOGLE_ANALYTICS_V4.key]: 'tpin_google_analyticsv4',
    [CONNECTORS_MAP.GOOGLE_DRIVE.key]: 'tpin_google_drive',
    [CONNECTORS_MAP.GOOGLE_SHEETS.key]: 'tpin_google_sheets',
    [CONNECTORS_MAP.HUBSPOT.key]: 'tpin_hubspot',
    [CONNECTORS_MAP.MAILCHIMP.key]: 'tpin_mailchimp',
    [CONNECTORS_MAP.MIXPANEL.key]: 'tpin_mixpanel',
    [CONNECTORS_MAP.MONGODB.key]: 'tpin_mongodb',
    [CONNECTORS_MAP.MSSQL.key]: 'tpin_mssql',
    [CONNECTORS_MAP.MYSQL.key]: 'tpin_mysql',
    [CONNECTORS_MAP.PARQUET.key]: 'tpin_parquet',
    [CONNECTORS_MAP.POSTGRES.key]: 'tpin_postgres',
    [CONNECTORS_MAP.REDSHIFT.key]: 'tpin_redshift',
    [CONNECTORS_MAP.SALESFORCE.key]: 'tpin_salesforcecdata',
    [CONNECTORS_MAP.SENDGRID.key]: 'tpin_sendgrid',
    [CONNECTORS_MAP.SFTP.key]: 'tpin_ftp',
    [CONNECTORS_MAP.SHAREPOINT.key]: 'tpin_sharepoint',
    [CONNECTORS_MAP.SHOPIFY.key]: 'tpin_shopify',
    [CONNECTORS_MAP.SNOWFLAKE.key]: 'tpin_snowflake',
    [CONNECTORS_MAP.XERO.key]: 'tpin_xero',
    [CONNECTORS_MAP.ZOOM.key]: 'tpin_zoom'
  },
  DATA_SYNC: 'dataSync',
  DATAFLOW_MAP: 'dataFlowMap',
  DATAFLOW_STATUS: 'globalPendingUpdates',
  DUPLICATE_VIEWS_WITH_DS: 'duplicateViewsWithDs',
  EXPORT: {
    DOWNLOAD_CSV: 'downloadCsv',
    DOWNLOAD_XLSX: 'downloadXLSX',
    [EXPORT_CONNECTORS_MAP.BIGQUERY.key]: 'exportToBigquery',
    [EXPORT_CONNECTORS_MAP.ELASTICSEARCH.key]: 'exportToElasticsearch',
    [EXPORT_CONNECTORS_MAP.MSSQL.key]: 'exportToMssql',
    [EXPORT_CONNECTORS_MAP.MYSQL.key]: 'exportToMysql',
    [EXPORT_CONNECTORS_MAP.POSTGRES.key]: 'exportToPostgresql',
    [EXPORT_CONNECTORS_MAP.REDSHIFT.key]: 'exportToRedshift',
    [EXPORT_CONNECTORS_MAP.SFTP.key]: 'sftp',
    LIVE_LINK: 'liveLink',
    PUBLISH_TO_DB: 'publishDB',
    SAVE_AS_DATASET: 'exportSaveAsDataset'
  },
  FUNCTIONS: {
    [STEP_SUBTYPES.BULK_COPY]: 'bulk_duplicate_columns'
  },
  PROJECTS: {
    CREATE: 'project_create'
  },
  SFTP_BULK_IMPORTS: 'sftpBulkImports',
  TEMPLATES: 'templates',
  TOGGLE_INTERFACE: 'toggleInterfaceV2'
}
