// Plugins
import keycloak from '@/plugins/keycloak'
import router from '@/plugins/router'

// API
import authApi from '@/modules/auth/auth.api'
import API from '@/api'

// Dependencies
import { jwtDecode } from 'jwt-decode'

// Utils
import { fetchData } from '@/utils/fetch'

const getInitialState = () => ({
  isAnonymousUser: false
})

const clearKeysFromLocalStorage = (keys_selector) => {
  Object.keys(localStorage)
    .filter(keys_selector)
    .forEach((key) => localStorage.removeItem(key))
}

const keysToClear = ['storageClass', 'account_id', 'workspaceId', 'card_filter']

const appKeysFilter = (key) => {
  return keysToClear.includes(key)
}

const mmCacheFilter = (key) => key.startsWith('mmCache_')

export const clearLocalStorage = () => {
  clearKeysFromLocalStorage(appKeysFilter)
  // clear the mmCache_ entries from local storage. This is being filled by the angular code. but logout logic is here, so cleaning it here.
  clearKeysFromLocalStorage(mmCacheFilter)
}

const getters = {
  isUserSuperAdmin: () => !!jwtDecode(keycloak.token)?.super_admin,
  isUserAuthenticated: () => keycloak.authenticated
}

const mutations = {
  setIsAnonymousUser: (state, isAnonymousUser) => (state.isAnonymousUser = isAnonymousUser)
}

const actions = {
  async logout({ commit, dispatch }) {
    commit('reset', null, { root: true })
    localStorage.setItem('authenticated', 'false')
    await dispatch('logoutKeycloak')
    clearLocalStorage()
    sessionStorage.removeItem('viewColDefsMap')
    localStorage.removeItem('keycloakSessionId')
    localStorage.removeItem('anonymousUserToken')
    localStorage.removeItem('anonymousUserRefreshToken')
    localStorage.removeItem('anonymousUserKeycloakId')
  },
  async logoutKeycloak() {
    if (keycloak?.authenticated) {
      await authApi.blacklistToken({ token: keycloak.token })
      await keycloak.logout()
    }
  },
  async setupAnonymousUserToken({ commit, dispatch }) {
    const setupToken = (token, refreshToken) => {
      API.defaults.headers.common.Authorization = `bearer ${token}`

      const decodedToken = jwtDecode(token)
      const tokenRefreshDate = new Date(decodedToken.exp * 1000)
      const timeUntilRefresh = tokenRefreshDate - Date.now()

      setTimeout(async () => {
        const { data } = await authApi.refreshToken(refreshToken)
        setupToken(data.access_token, data.refresh_token)
        await authApi.setSecurityCookie()
      }, timeUntilRefresh)
    }

    const { query } = router.currentRoute
    if (query.token) localStorage.setItem('anonymousUserToken', query.token)
    if (query.refresh_token) localStorage.setItem('anonymousUserRefreshToken', query.refresh_token)
    if (query.kc_user_id) localStorage.setItem('anonymousUserKeycloakId', query.kc_user_id)
    router.replace({ query: {} })

    const anonymousUserToken = localStorage.getItem('anonymousUserToken')
    const anonymousUserRefreshToken = localStorage.getItem('anonymousUserRefreshToken')

    setupToken(anonymousUserToken, anonymousUserRefreshToken)
    await dispatch('logoutKeycloak')
    await authApi.setSecurityCookie()
    commit('setIsAnonymousUser', true)
    await fetchData()
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
