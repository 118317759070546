import store from '@/store'

export default [
  {
    path: '/landing',
    name: 'DataLibrary',
    component: () => (store.state.isInterfaceV1Active ? import('./data-library.vue') : import('./data-library-v2.vue'))
  },
  {
    path: '/guest/workspaces/:workspaceId/projects/:projectId',
    name: 'AnonymousProject',
    component: () => import('./anonymous-data-library.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/workspaces/:workspaceId/projects/:projectId',
    name: 'Project',
    component: () => (store.state.isInterfaceV1Active ? import('./data-library.vue') : import('./data-library-v2.vue'))
  },
  {
    path: '/workspaces/:workspaceId/projects/:projectId/folders/:folderId',
    name: 'Folder',
    component: () => (store.state.isInterfaceV1Active ? import('./data-library.vue') : import('./data-library-v2.vue'))
  },
  {
    path: '/workspaces/:workspaceId/reports',
    name: 'Reports',
    component: () => (store.state.isInterfaceV1Active ? import('./data-library.vue') : import('./data-library-v2.vue')),
    props: { showReportsList: true }
  },
  {
    path: '/onboarding',
    name: 'DataLibraryOnboarding',
    component: () =>
      store.state.isInterfaceV1Active
        ? import('./components/onboarding/data-library-onboarding.vue')
        : import('./components/onboarding/data-library-onboarding-v2.vue')
  }
]
