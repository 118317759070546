// API
import API from '@/api'
import globalApi from '@/api/global.api'

// Constants
import { REQUESTS_STATUS } from '@/constants'

// Transforms
import { transformStep } from '@/api/global.transform'
import {
  transformDisplayProperties,
  transformGridColumnsMetaData,
  transformTaskFailureResponse,
  transformTaskErrorResponse,
  transformDerivatives
} from '@/modules/data-editor/api/data-editor.transform'
import { transformUsers } from '@/api/global.transform.js'

const addTask = async (viewId, payload, sequenceNumber) => {
  const apiResponse = await API.post(
    `dataviews/${viewId}/tasks`,
    {
      param: { ...payload, SEQUENCE_NUMBER: sequenceNumber }
    },
    { customErrorHandling: true }
  ).catch((error) => error)
  if (!apiResponse) return

  const { data } = apiResponse
  let response
  if (data?.information?.status == REQUESTS_STATUS.PROCESSING_1)
    response = await globalApi.getFutureRequest(data?.information.future_id)
  else if (data?.information?.status == REQUESTS_STATUS.DONE) response = data.information
  else if (data?.information?.status == REQUESTS_STATUS.ERROR) response = transformTaskErrorResponse(data.information)
  else if (data?.STATUS == REQUESTS_STATUS.FAILURE) response = transformTaskFailureResponse(data)

  return response
}

const addAction = async (viewId, payload) => {
  const apiResponse = await API.post(
    `dataviews/${viewId}/actions`,
    { param: payload },
    { customErrorHandling: true }
  ).catch((error) => error)
  if (!apiResponse) return

  const { data } = apiResponse
  const response = await globalApi.getFutureRequest(data.future_id)
  return response
}

const editTask = async (viewId, stepId, value) => {
  const apiResponse = await API.patch(
    `dataviews/${viewId}/tasks/${stepId}`,
    { patch: [{ op: 'replace', path: 'params', value }] },
    { customErrorHandling: true }
  ).catch((error) => error)
  if (!apiResponse) return

  const { data } = apiResponse
  let response
  if (data?.information?.status == REQUESTS_STATUS.PROCESSING_1)
    response = await globalApi.getFutureRequest(data.information.future_id)
  else if ([REQUESTS_STATUS.DONE, REQUESTS_STATUS.ERROR].includes(data?.information?.status))
    response = data?.information
  else if (data?.STATUS == REQUESTS_STATUS.FAILURE) response = transformTaskFailureResponse(data)
  return response
}

const editAction = async (viewId, stepId, value) => {
  const apiResponse = await API.patch(
    `dataviews/${viewId}/actions/${stepId}`,
    { patch: [{ op: 'replace', path: 'params', value }] },
    { customErrorHandling: true }
  ).catch((error) => error)
  if (!apiResponse) return

  const { data } = apiResponse
  const response = await globalApi.getFutureRequest(data.future_id)
  return response
}

const getPipelineInfo = async (viewId) => {
  const { data } = await API.get(`dataviews/${viewId}/pipeline-info`).catch((error) => console.error(error))
  return data
}

const getPipelineTaskStatuses = async (viewId) => {
  const response = await API.get(`dataviews/${viewId}/task-statuses`)
  return response?.data?.statuses || {}
}

async function getViewInfo(viewId, sequenceNumber, includeRowCount = true) {
  const { data } = await API.get(`/dataviews/${viewId}`, {
    params: {
      SEQUENCE_NUMBER: sequenceNumber,
      INCLUDE_ROW_COUNT: includeRowCount
    }
  })
  const response = await globalApi.getFutureRequest(data.future_id, `getViewInfo_${viewId}`)
  if (!response) return

  if (response.status === REQUESTS_STATUS.ERROR)
    return {
      error_code: response.error_code
    }
  const { columnDefs } = transformGridColumnsMetaData(response.metadata, response.display_properties)
  const displayProperties = transformDisplayProperties(response.display_properties)

  return {
    columnDefs,
    rowCount: response.row_count,
    displayProperties,
    dependenciesInfo: response.dependencies_info
  }
}

/**
 *
 * @param {string} viewId the data-view we want to request data for
 * @param {string} offset the first row we want to start the request from
 * @param {string} limit the number of rows we want to request
 * @param {string} filterCondition filters can be applied to the dataset, if there is any, it comes here
 * @param {string} columns list of columns to show the data for
 */

async function getViewData({ viewId, offset = 0, limit = 400, filterCondition = null, columns = null, sequence = 0 }) {
  let params = {
    limit,
    offset,
    SEQUENCE_NUMBER: sequence,
    condition: filterCondition
  }
  if (Array.isArray(columns) && columns.length) {
    params.columns = columns
  }
  const { data } = await API.post(`/dataviews/${viewId}/data`, params)
  return await globalApi.getFutureRequest(data.future_id, 'getViewData')
}

export async function getRowCount({ viewId, filterCondition, sequence }) {
  const param = {
    PIVOT: {
      SELECT: [
        {
          FUNCTION: 'COUNT',
          AS: 'COUNT',
          INTERNAL_NAME: 'count'
        }
      ]
    },
    CONDITION: filterCondition
  }

  if (Number.isInteger(sequence)) param.SEQUENCE_NUMBER = sequence

  const { data } = await API.post(`/dataviews/${viewId}/data/query`, { param, display_properties: {} })
  const results = await globalApi.getFutureRequest(data.future_id)
  const currentRowCount = results.data[0]?.count ?? 0
  return currentRowCount
}

async function getDataSample(viewId, param, displayProperties) {
  const response = await API.post(`/dataviews/${viewId}/data/query`, {
    param,
    display_properties: displayProperties
  }).catch((error) => console.error('Error fetching sample values ', error))
  if (!response) return
  const results = await globalApi.getFutureRequest(response?.data.future_id)
  return results
}

async function getFunctionSuggestions(param) {
  const response = await API.post(`/suggestions`, param).catch((error) => error)
  if (!response) return
  if (response.data?.future_id) return await globalApi.getFutureRequest(response.data.future_id)
  return response?.data
}

const getUsersViewingView = (viewId) => API.get(`dataviews/${viewId}/user`)

const setDisplayProperties = async ({ viewId, displayProperties }) => {
  const { data } = await API.post(`dataviews/${viewId}`, { display_properties: displayProperties })
  const response = await globalApi.getFutureRequest(data.future_id)
  if (response.status !== REQUESTS_STATUS.ERROR) {
    const { columnDefs } = transformGridColumnsMetaData(response.metadata, response.display_properties)
    const displayProperties = transformDisplayProperties(response.display_properties)
    return { columnDefs, rowCount: response.row_count, displayProperties, status: response.status }
  }
  return response
}

export default {
  runAction: async (viewId, stepId) => {
    const { data } = await API.post(`dataviews/${viewId}/actions/${stepId}`)
    return await globalApi.getFutureRequest(data.future_id)
  },
  regeneratePublishPassword: async (viewId) => {
    const { data } = await API.patch(`dataviews/${viewId}/publish`, {
      patch: [{ op: 'replace', path: 'reset_password', value: { odbc_type: 'postgres' } }]
    })
    return await globalApi.getFutureRequest(data.future_id)
  },
  renameDisplayInfo: async (viewId, stepId, payload) => {
    const response = await API.patch(`dataviews/${viewId}/tasks/${stepId}`, {
      patch: [{ op: 'replace', path: 'display_info', value: payload }]
    })
    return response.data.information.response
  },
  runBulkOperationInPipeline: async (viewId, operation, value, skipValidation) => {
    const { data } = await API.patch(`dataviews/${viewId}/pipeline?skip_validation=${!!skipValidation}`, {
      patch: [{ op: 'replace', path: operation, value }]
    })
    const futureId = data?.information?.future_id || data?.information?.tasks?.future_id
    if (futureId) return await globalApi.getFutureRequest(futureId)
    return data
  },
  getViewTasks: async (viewId) => {
    const { data } = await API.get(`dataviews/${viewId}/tasks`)
    return (await globalApi.getFutureRequest(data.future_id)).tasks?.map((t) => transformStep(t))
  },
  getViewActions: async (viewId) => {
    const { data } = await API.get(`dataviews/${viewId}/actions`)
    const futureId = data.future_id
    if (!futureId) return data.triggers?.map((t) => transformStep(t))
    const response = await globalApi.getFutureRequest(data.future_id)
    return response.triggers?.map((t) => transformStep(t))
  },
  toggleStepsDataSyncStatus: async (steps, status, individualStatusProperty) => {
    const { data } = await API.patch(
      'resource_dependencies',
      steps.map((step) => ({
        context_id: step.id,
        context_type: step.type.toLowerCase(),
        data_pass_through: individualStatusProperty ? step[individualStatusProperty] : status
      }))
    )
    return await globalApi.getFutureRequest(data.future_id)
  },
  toggleRunPendingUpdate: async (operations, status) => {
    const { data } = await API.patch(
      'resource_dependencies',
      operations.map((op) => ({
        context_id: op.opId,
        context_type: op.opType.toLowerCase(),
        run_pending_update: status
      }))
    )
    return await globalApi.getFutureRequest(data.future_id)
  },
  getDerivatives: async (viewId) => {
    const { data } = await API.get(`dataviews/${viewId}/derivatives`)
    return transformDerivatives(data.derivatives)
  },
  patchDerivative: async (viewId, derivativeId, payload) =>
    await API.patch(
      `dataviews/${viewId}/derivatives/${derivativeId}`,
      { patch: payload },
      { customErrorHandling: true }
    ).catch((error) => error),
  getMetricResult: async (viewId, metricId, payload, condition) => {
    if (metricId) {
      const { data } = await API.post(`dataviews/${viewId}/derivatives/${metricId}`, {
        CONDITION: condition,
        LIMIT: null
      })
      return data
    }

    const response = await API.post(`dataviews/${viewId}/data/query`, payload)
    return await globalApi.getFutureRequest(response?.data.future_id)
  },
  deleteDerivative: async (viewId, derivativeId) => {
    const { data } = await API.delete(`dataviews/${viewId}/derivatives/${derivativeId}`)
    return await globalApi.getFutureRequest(data.future_id)
  },
  createDerivative: async (viewId, payload) => {
    const { data } = await API.post(`dataviews/${viewId}/derivatives`, payload, { customErrorHandling: true }).catch(
      (error) => error
    )
    return data
  },
  getViewInfo,
  getViewData,
  getRowCount,
  getPipelineInfo,
  getPipelineTaskStatuses,
  setDisplayProperties,
  addTask,
  addAction,
  editTask,
  editAction,
  getUsersViewingView: async (viewId) => transformUsers(await getUsersViewingView(viewId)),
  getDataSample,
  getFunctionSuggestions
}
