// Constants
import { RESOURCE_DRAFT_STATUS, RESOURCE_STATUS, STEP_TYPES, STEP_STATUS, RESOURCE_PIPELINE_STATUS } from '@/constants'

// API
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'

const getInitialState = () => ({
  isLoading: false,
  steps: [],
  isReorderModeActive: false,
  isSelectModeActive: false,
  stepsSelection: {},
  cardsDensity: 'large',
  taskStatuses: {}
})

const getters = {
  isEmpty: (state) => !state.steps.length,
  isRestMode: (state) => !(state.isReorderModeActive || state.isSelectModeActive),
  isInError: (state) =>
    state.steps.some(
      (s) => s.isInError && ![STEP_STATUS.DELETED, STEP_STATUS.SUSPENDED, STEP_STATUS.SUSPENDING].includes(s.status)
    ),
  isDraftMode: (state, getters, rootState, rootGetters) =>
    rootGetters['dataEditor/view']?.properties.draftModeStatus != RESOURCE_DRAFT_STATUS.OFF,
  isLoading: (state, getters, rootState, rootGetters) =>
    state.isLoading || rootGetters['dataEditor/view']?.properties.status == RESOURCE_STATUS.PROCESSING,
  isTasksTrackingActive: (state, getters, rootState, rootGetters) => {
    const view = rootGetters['dataEditor/view']
    if (!view) return
    return (
      view.properties.status == RESOURCE_STATUS.PROCESSING ||
      [RESOURCE_PIPELINE_STATUS.RUNNING, RESOURCE_PIPELINE_STATUS.SUBMITTED].includes(view.properties.pipelineStatus)
    )
  },
  taskCount: (state, getters, rootState, rootGetters) => rootGetters['dataEditor/view']?.properties.tasksQuantity,
  getStepByTypeAndSequence: (state) => (type, sequence) =>
    state.steps.find((s) => s.type == type && s.sequence === sequence),
  stepsWithChanges: (state) =>
    state.steps.filter(
      (s) =>
        [STEP_STATUS.ADDED, STEP_STATUS.EDITED, STEP_STATUS.DELETED, STEP_STATUS.SUSPENDING].includes(s.status) ||
        s.reordered
    )
}

const mutations = {
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  setSteps: (state, steps) => (state.steps = steps),
  setIsReorderModeActive: (state, isReorderModeActive) => (state.isReorderModeActive = isReorderModeActive),
  setIsSelectModeActive: (state, isSelectModeActive) => (state.isSelectModeActive = isSelectModeActive),
  setStepsSelection: (state, stepsSelection) => (state.stepsSelection = stepsSelection),
  setCardsDensity: (state, cardsDensity) => (state.cardsDensity = cardsDensity),
  setTaskStatuses: (state, taskStatuses) => (state.taskStatuses = taskStatuses)
}

const actions = {
  async getSteps({ commit, dispatch }) {
    commit('setIsLoading', true)

    const { viewId } = this.state.dataEditor
    const [tasks, actions] = await Promise.all([
      dataEditorApi.getViewTasks(viewId),
      dataEditorApi.getViewActions(viewId)
    ])
    const tasksAndActions = [...tasks, ...actions]
    tasksAndActions.sort(sortStepsFn)

    const reports = this.getters['resources/getReports']
      .filter((r) => r.properties.reportViewId == viewId && r.status != RESOURCE_STATUS.DELETED)
      .map((r) => ({ ...r, isPinned: true }))
    const steps = [...tasksAndActions, ...reports]
    commit('setSteps', steps)
    commit('setIsLoading', false)

    dispatch('trackActionStepsStatus')
  },
  async getActionSteps({ commit, state, dispatch }, ignoreLoading) {
    if (!ignoreLoading) commit('setIsLoading', true)

    const { viewId } = this.state.dataEditor
    const actions = await dataEditorApi.getViewActions(viewId)
    const filteredSteps = state.steps.filter((s) => s.type != STEP_TYPES.ACTION)
    const allSteps = [...filteredSteps, ...actions]
    allSteps.sort(sortStepsFn)
    commit('setSteps', allSteps)
    if (!ignoreLoading) commit('setIsLoading', false)

    dispatch('trackActionStepsStatus')
  },
  trackActionStepsStatus({ state, dispatch }) {
    // Check if any action is executing
    // If yes, keep pooling to track its status, to show progress bar on the pipeline step

    const isAnyActionExecuting = state.steps.some(
      (step) => step.type == STEP_TYPES.ACTION && step.status == STEP_STATUS.EXECUTING
    )
    if (isAnyActionExecuting) setTimeout(() => dispatch('getActionSteps', true), 2000)
  }
}

const sortStepsFn = (a, b) => {
  if (a.reportId) return 1
  if (b.reportId) return -1

  const aSequence = a.sequence == null || a.isPinned ? Number.MAX_SAFE_INTEGER : a.sequence
  const bSequence = b.sequence == null || b.isPinned ? Number.MAX_SAFE_INTEGER : b.sequence
  if (aSequence != bSequence) return aSequence - bSequence
  if (a.type == STEP_TYPES.TASK) return -1
  if (b.type == STEP_TYPES.TASK) return 1
  return a.subSequence - b.subSequence
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
