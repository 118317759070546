// API
import API from '@/api'
import globalApi from '@/api/global.api'
import { isEmpty } from 'lodash'
import moment from 'moment'

// Constants
import { PROJECT_PERMISSIONS, WORKSPACE_STATUS, ONBOARDING_FEATURES, SMS_STATUS } from '@/constants'

// Plugins
import { initializeSplitIo } from '@/plugins/splitio'
import router from '@/plugins/router'

// Utils
import { getLatestUpdates } from '@/utils/fetch'

const getInitialState = () => ({
  projectId: localStorage.getItem('projectId') || -1,
  folderId: localStorage.getItem('folderId') || -1,
  usageInfo: {
    workspace: {
      current: -1,
      allowed: -1
    }
  },
  user: {
    name: localStorage.getItem('name') || '',
    firstName: '',
    lastName: '',
    email: localStorage.getItem('email') || '',
    id: -1,
    profilePic: '',
    organisation: '',
    createdAt: '',
    roles: {
      projects: {},
      workspaces: {}
    }
  },
  workspaces: [
    /* {
      name: '',
      id: -1,
      subscription: {},
      userPermissions: [],
      projects: [
        { 
          id: -1,
          name: '',
          subscribed: boolean,
          updatedAt: '',
          datasetQuantity: 
        }
      ],
      workspaceUsers: -1
    } */
  ],
  workspaceId: localStorage.getItem('workspaceId') || -1,
  smsDetails: {},
  lastCheckedUntil: 0,
  isWorkspaceSnoozed: false,
  isNetworkSlow: false,
  isAppMaintenanceOngoing: false,
  onboardingFlags: {},
  areProjectResourcesFetched: {
    /*{
      *projectId*: Boolean
    }*/
  },
  isInterfaceV1Active: localStorage.getItem('isInterfaceV1Active') != 'false'
})

const getters = {
  isWorkspaceUsageLimitReached: (state) =>
    state.usageInfo.workspace.allowed > 0 && state.usageInfo.workspace.current >= state.usageInfo.workspace.allowed,
  isOnboardingDataLibraryActive: (state, getters) =>
    state.onboardingFlags[ONBOARDING_FEATURES.SELF_SERVE] == 'crm_added' &&
    getters.getCurrentWorkspace?.status == WORKSPACE_STATUS.ACTIVE,
  getCurrentProject: (state) =>
    getters.getCurrentWorkspace(state).projects?.find((project) => project.id == state.projectId),
  getCurrentWorkspace: (state) => state.workspaces.find((workspace) => workspace.id == state.workspaceId) || {},
  getCurrentWorkspacePermissions: (state) => state.user.roles.workspaces[state.workspaceId],
  getCurrentProjectPermissions: (state) => state.user.roles.projects[state.projectId],
  getCurrentWorkspaceProjects: (state, getters) => getters.getCurrentWorkspace?.projects,
  getSubscribedProjects: (state, getters) =>
    getters.getCurrentWorkspaceProjects
      ?.filter((proj) => proj.subscribed)
      .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt)),
  getProjectById: (state, getters) => (id) => getters.getCurrentWorkspaceProjects.find((proj) => proj.id === id),
  isWorkspaceIncomplete: (state, getters) =>
    getters.getCurrentWorkspace?.status == WORKSPACE_STATUS.INITIALIZED ||
    state.onboardingFlags[ONBOARDING_FEATURES.SELF_SERVE] == 'registered',
  isWorkspaceTrialExpired: (state) =>
    state.smsDetails?.status && ![SMS_STATUS.ACTIVE, SMS_STATUS.TRIAL].includes(state.smsDetails.status)
}

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} project { name, id, updatedAt }
   */
  addProject(state, project) {
    getters.getCurrentWorkspace(state).projects.push(project)
    state.user.roles.projects[project.id] = PROJECT_PERMISSIONS.ADMIN
  },
  deleteProject(state, projectId) {
    let projects = getters.getCurrentWorkspace(state).projects
    projects = projects.filter((p) => p.id != projectId)

    const nextProjectId = projects.find((p) => p.subscribed)?.id || -1
    this.commit('setProjectId', nextProjectId)
    if (nextProjectId == -1) router.push({ name: 'DataLibrary' })
  },
  reset(state) {
    Object.assign(state, getInitialState())
  },
  setFolderId(state, folderId) {
    state.folderId = folderId
    localStorage.setItem('folderId', folderId)
  },
  setProjectId(state, projectId) {
    state.projectId = isNaN(projectId) ? -1 : projectId
    localStorage.setItem('projectId', state.projectId)
    state.lastCheckedUntil = 0
  },
  setProjectName(state, { projectId, newName }) {
    const project = getters.getCurrentWorkspace(state).projects.find((project) => project.id == projectId)
    project.name = newName
  },
  setUser(state, user) {
    //Copy values of existing keys
    Object.keys(state.user).forEach((key) => {
      if (user[key] !== undefined) {
        state.user[key] = user[key]

        if (['name', 'email'].includes(key)) localStorage.setItem(key, user[key])
      }
    })

    window.Intercom?.('update', {
      user_id: state.user.id,
      name: state.user.name,
      email: state.user.email
    })
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'userLoaded',
      userId: state.user.id,
      name: state.user.name,
      email: state.user.email,
      workspaceId: state.workspaceId,
      createdAt: moment.utc(state.user.createdAt).format('lll')
    })
  },
  setWorkspaces(state, workspaces) {
    state.workspaces = workspaces
  },
  setIsWorkspaceSnoozed(state, isWorkspaceSnoozed) {
    state.isWorkspaceSnoozed = isWorkspaceSnoozed
  },
  setIsNetworkSlow(state, isNetworkSlow) {
    state.isNetworkSlow = isNetworkSlow
  },
  setIsAppMaintenanceOngoing(state, isAppMaintenanceOngoing) {
    state.isAppMaintenanceOngoing = isAppMaintenanceOngoing
  },
  setAreProjectResourcesFetched(state, status) {
    state.areProjectResourcesFetched[state.projectId] = status
  },
  setWorkspaceId(state, workspaceId) {
    if (!workspaceId || isNaN(workspaceId)) return

    API.defaults.headers['X-WORKSPACE-ID'] = workspaceId
    if (state.workspaceId == workspaceId) return

    state.workspaceId = workspaceId
    localStorage.setItem('workspaceId', workspaceId)
    this.commit('setCurrentWorkspaceUsage', -1)
    this.commit('setAllowedWorkspaceUsage', -1)
    initializeSplitIo()
    getLatestUpdates()
  },
  setLastCheckedUntil: (state, lastCheckedUntil) => {
    if (lastCheckedUntil != null) state.lastCheckedUntil = lastCheckedUntil
  },
  setSmsDetails: (state, smsDetails) => {
    if (smsDetails) {
      state.smsDetails = {
        ...smsDetails,
        availableAddons: smsDetails.availableAddons || state.smsDetails.availableAddons,
        availablePlans: smsDetails.availablePlans || state.smsDetails.availablePlans
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'smsDetailsLoaded',
        plan: state.smsDetails.currentPlan?.name
      })
      if (getters.isWorkspaceIncomplete(state, getters)) router.push({ name: 'Workspace', params: state })
    }
  },
  setAllowedWorkspaceUsage: (state, newAllowedUsage) =>
    (state.usageInfo.workspace = { ...state.usageInfo.workspace, allowed: Number(newAllowedUsage) }),
  setCurrentWorkspaceUsage: (state, newCurrentUsage) =>
    (state.usageInfo.workspace = { ...state.usageInfo.workspace, current: Number(newCurrentUsage) }),
  updateProject(state, newProject) {
    getters.getCurrentWorkspace(state).projects.find((project) => (project.id = newProject.id))
  },
  setOnboardingFlags: (state, flags) => (state.onboardingFlags = flags),
  setOnboardingFlag: (state, { flag, status }) => (state.onboardingFlags = { ...state.onboardingFlags, [flag]: status })
}

const actions = {
  saveWorkspaces(store, workspaces) {
    store.commit('setWorkspaces', workspaces)

    if (!workspaces.length) {
      setTimeout(() => store.dispatch('getWorkspaces', { invalidateCache: true }), 4000)
      return
    }

    //Check if current workspaceId is present on the workspaces array
    const workspaceIdExists = workspaces.some((workspace) => workspace.id == store.state.workspaceId)
    if (store.state.workspaceId == -1 || !workspaceIdExists) store.commit('setWorkspaceId', workspaces[0].id)
    else initializeSplitIo()

    if (store.getters.isWorkspaceIncomplete) router.push({ name: 'Workspace', params: store.state })
  },
  async getCurrentUser(store, config) {
    if (store.state.user.id === -1 || !isEmpty(config)) {
      const data = await globalApi.getCurrentUser(config)
      store.commit('setUser', data)
    }
  },
  async getWorkspaceUsage(store) {
    const usage = await globalApi.getUsageInfo(store.state.workspaceId, store.state.user.id)
    if (usage.workspace.allowedUsage != null) store.commit('setAllowedWorkspaceUsage', usage.workspace.allowedUsage)
    if (usage.workspace.currentUsage != null) store.commit('setCurrentWorkspaceUsage', usage.workspace.currentUsage)
  },
  async getWorkspaces(store, config) {
    store.dispatch('saveWorkspaces', await globalApi.getWorkspaces(config))
  },
  async getSmsDetails(store, config) {
    const smsDetails = await globalApi.getSmsDetails(store.state.workspaceId, config)
    store.commit('setSmsDetails', smsDetails)
  },
  setCurrentWorkspace(store, workspaceId) {
    store.commit('setWorkspaceId', workspaceId)
    const workspace = store.state.workspaces.find((workspace) => workspace.id === workspaceId)
    const project = workspace.projects.find((project) => project.subscribed)
    if (project) router.push({ name: 'Project', params: { workspaceId, projectId: project.id } })
    else router.push({ name: 'DataLibrary' })
    store.dispatch('getSmsDetails')
  }
}

export default {
  state: getInitialState(),
  getters,
  mutations,
  actions
}
