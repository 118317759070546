import moment from 'moment'
import dateformat from 'dateformat'
import {
  DATE_GRANULARITY_OPTIONS,
  DATE_GRANULARITY_ORDER,
  MAX_SAMPLE_RUN_COUNT,
  DATA_TYPES_MAP,
  EXPLORE_CARD_TYPES
} from '@/constants'
import { filter } from 'lodash'
import i18n from '@/plugins/i18n'

export function isNullCondition(condition, columnId) {
  if (Array.isArray(condition)) return condition.some((subCondition) => isNullCondition(subCondition, columnId))

  if (condition.AND) return condition.AND.some((subCondition) => isNullCondition(subCondition, columnId))

  if (condition.OR) return condition.OR.some((subCondition) => isNullCondition(subCondition, columnId))

  return condition[columnId]?.IS_EMPTY === true
}

export function getBoundaries(selected, columnType, level) {
  let s1 = selected[0]
  if (columnType == DATA_TYPES_MAP.NUMERIC) {
    s1 = parseFloat(s1)
  }
  let s2 = selected.at(-1)
  let upperBound = getRangeUpperBound(columnType, s2, level)
  return {
    lower: s1,
    upper: upperBound
  }
}

export function getGranularityOptions(maxLevel = 10, minLevel = -3) {
  let options = []
  for (let level = maxLevel; level >= minLevel; level--) {
    options.push({
      id: level,
      name: Math.pow(10, level).toLocaleString()
    })
  }
  return options
}

function getGranularityByLevel(level) {
  return {
    id: level,
    name: Math.pow(10, level).toLocaleString()
  }
}

export function getGranularityOption(columnType, level) {
  if (columnType == DATA_TYPES_MAP.NUMERIC) {
    return getGranularityByLevel(level)
  }
  if (columnType == DATA_TYPES_MAP.DATE) {
    return filter(DATE_GRANULARITY_OPTIONS, { id: level })[0]
  }
}

export function getNumRangeUpperBound(val, pow) {
  const pow10 = Math.pow(10, pow)
  const value = parseFloat(val) + pow10
  if (pow < 0) {
    const newValue = Math.round(value / pow10) * pow10
    return parseFloat(newValue.toFixed(-1 * pow))
  }
  return Math.round(value)
}

export function getDateRangeUpperBound(val, level = 'DAY') {
  const momentMap = {
    YEAR: 'years',
    MONTH: 'months',
    DAY: 'days',
    HOUR: 'hours',
    MINUTE: 'minutes',
    SECOND: 'seconds'
  }
  // Replacing hyphen with slash because hyphenated formats do not work on safari
  // Ref: https://stackoverflow.com/questions/4310953/invalid-date-in-safari
  val = val.replace(/-/g, '/')
  return moment(new Date(val)).add(1, momentMap[level]).format('YYYY-MM-DD HH:mm:ss')
}

export function getRangeUpperBound(type, val, level) {
  const mapped = {
    NUMERIC: getNumRangeUpperBound,
    DATE: getDateRangeUpperBound
  }
  return mapped[type](val, level)
}

export function numberWithCommas(x) {
  x = parseFloat(x)
  return x.toLocaleString()
}

export function numberWithoutCommas(x) {
  return String(x).replace(/,/g, '')
}

export function formatDataForExplore(columnType, value, granularity, queryIndex, minInBucket, maxInBucket) {
  if (value === null) return `(${i18n.t('global.dictionary.blank').toLowerCase()})`
  if (columnType == DATA_TYPES_MAP.NUMERIC) {
    if (minInBucket == maxInBucket && parseFloat(minInBucket) == parseFloat(value)) {
      return numberWithCommas(value)
    }
    const startValue = numberWithCommas(value)
    const endValue = numberWithCommas(getRangeUpperBound(columnType, value, granularity.id))
    return `${startValue} ${i18n.t('global.dictionary.to')} ${endValue}`
  }
  if (columnType === DATA_TYPES_MAP.DATE) {
    const dateFormat = queryIndex === 0 ? granularity.listFormat : granularity.graphFormat
    return dateformat(moment(value), dateFormat)
  }
  return value
}

function _guessLevel(columnType, val1, val2, offset) {
  if (columnType === DATA_TYPES_MAP.DATE) {
    return _guessDateLevel(val1, val2, offset)
  }
  if (columnType === DATA_TYPES_MAP.NUMERIC) {
    return _guessNumericLevel(val1, val2, offset)
  }
  return null
}

function _guessDateLevel(val1, val2, offset) {
  const parseDateParts = (dateStr) => {
    const [datePart, timePart] = dateStr.split(/[T ]/)
    const dateParts = datePart.split('-')
    const timeParts = timePart ? timePart.split(':') : []
    return [...dateParts, ...timeParts]
  }

  const parts1 = parseDateParts(val1.replace('Z', ''))
  const parts2 = parseDateParts(val2.replace('Z', ''))

  for (let i = 0; i < parts1.length; i++) {
    if (parts1[i] !== parts2[i]) {
      return i === 0 ? DATE_GRANULARITY_ORDER[i] : DATE_GRANULARITY_ORDER[i - offset]
    }
  }
  return 'DAY'
}

function _guessNumericLevel(val1, val2, offset) {
  val1 = parseFloat(val1)
  val2 = parseFloat(val2)

  if (offset === 1) {
    if (val1 === val2) {
      return Number.isInteger(val1) ? 0 : -1
    }
    return 0
  }
  if (offset === 0) {
    const diff = Math.abs(val2 - val1).toFixed(4)
    if (diff == 0) return 0

    let level = Math.floor(Math.log10(diff))
    while (level >= 0) {
      if (Number.isInteger(val1 / 10 ** level)) {
        return level
      }
      level--
    }
    return level
  }
  return 0
}

export function guessRangeExploreCardLevel(level, columnType, data) {
  if (level !== 'AUTO') return level

  const nonEmptyData = data.filter((d) => d.value !== null)
  if (nonEmptyData.length === 0) return columnType === DATA_TYPES_MAP.DATE ? 'DAY' : 0

  if (nonEmptyData.length === 1) return _guessLevel(columnType, nonEmptyData[0].min, nonEmptyData[0].max, 1)

  const samplesRunCount = Math.min(nonEmptyData.length - 1, MAX_SAMPLE_RUN_COUNT)
  const levels = {}

  for (let r = 0; r < samplesRunCount; r++) {
    const guessedLevel = _guessLevel(columnType, nonEmptyData[r].value, nonEmptyData[r + 1].value, 0)
    levels[guessedLevel] = (levels[guessedLevel] || 0) + 1
  }

  const mostCommonLevel = Object.entries(levels).reduce((a, b) => (b[1] > a[1] ? b : a))[0]

  return columnType === DATA_TYPES_MAP.NUMERIC ? parseInt(mostCommonLevel, 10) : mostCommonLevel
}

export function getExploreFilterDescription(columnType, activeValues, level) {
  const hasEmptyValues = activeValues.some((v) => v.value === null)
  const values = activeValues.filter((v) => v.value !== null)

  if (!values.length) return hasEmptyValues ? i18n.t('global.dictionary.blank').toLowerCase() : ''

  if (values.length === 1) {
    let formattedValue = values[0].formatted
    if (columnType === DATA_TYPES_MAP.DATE) {
      const granularity = getGranularityOption(columnType, level)
      const dateFormat = granularity.listFormat
      formattedValue = dateformat(moment(values[0].value), dateFormat)
    }
    return hasEmptyValues
      ? `${i18n.t('global.dictionary.blank').toLowerCase()} ${i18n
          .t('global.dictionary.or')
          .toLowerCase()} ${formattedValue}`
      : formattedValue
  }

  let startValue = ''
  let endValue = ''

  if (columnType === DATA_TYPES_MAP.DATE) {
    const granularity = getGranularityOption(columnType, level)
    const dateFormat = granularity.listFormat
    startValue = dateformat(moment(values[0].value), dateFormat)
    endValue = dateformat(moment(values.at(-1).value), dateFormat)
  } else if (columnType === DATA_TYPES_MAP.NUMERIC) {
    startValue = numberWithCommas(values[0].value)
    endValue = numberWithCommas(getRangeUpperBound(columnType, values.at(-1).value, level))
  }

  return hasEmptyValues
    ? `${i18n.t('global.dictionary.blank').toLowerCase()} ${i18n
        .t('global.dictionary.or')
        .toLowerCase()} ${startValue} ${i18n.t('global.dictionary.to')} ${endValue}`
    : `${startValue} ${i18n.t('global.dictionary.to')} ${endValue}`
}

export function getPivotSelectParam(aggType, argInternalName, minMaxCol) {
  let result
  if (aggType == 'COUNT') {
    result = [
      { FUNCTION: 'COUNT', AS: 'count', INTERNAL_NAME: 'agg' },
      { FUNCTION: 'COUNT', AS: 'Unformatted', INTERNAL_NAME: 'unformatted' },
      { FUNCTION: 'PERCENTAGE', AS: 'percentage', INTERNAL_NAME: 'percentage', AGGREGATION: 'COUNT' }
    ]
  } else {
    result = [
      { FUNCTION: aggType, AS: aggType, INTERNAL_NAME: 'agg', COLUMN: argInternalName },
      { FUNCTION: aggType, AS: 'Unformatted', INTERNAL_NAME: 'unformatted', COLUMN: argInternalName },
      {
        FUNCTION: 'PERCENTAGE',
        AS: 'percentage',
        INTERNAL_NAME: 'percentage',
        AGGREGATION: aggType,
        COLUMN: argInternalName
      }
    ]
  }
  if (minMaxCol) {
    result.push({ FUNCTION: 'MIN', INTERNAL_NAME: 'min', AS: 'MIN_VAL', COLUMN: minMaxCol })
    result.push({ FUNCTION: 'MAX', INTERNAL_NAME: 'max', AS: 'MAX_VAL', COLUMN: minMaxCol })
  }
  return result
}

export function getInitialConditionFromConfig(config, initialCondition = null) {
  const conditionMap = {}
  config.forEach((card) => {
    const id = card.colId || card.queryIndex
    conditionMap[id] = initialCondition
    if (initialCondition === null) {
      initialCondition = card.condition
    } else if (card.condition) {
      initialCondition = { AND: [initialCondition, card.condition] }
    }
  })
  return conditionMap
}

export function getValueHeader(card, column) {
  let valueHeader = card.aggregation.type
  const targetDisplayName = column.name
  if (valueHeader != 'COUNT' && targetDisplayName) {
    valueHeader += `(${targetDisplayName})`
  }
  return valueHeader
}

export function downloadStringDataAsFile(strData, strFileName, strMimeType = 'application/octet-stream') {
  const blob = new Blob([strData], { type: strMimeType })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.style.display = 'none'
  a.href = url
  a.download = strFileName

  document.body.appendChild(a)
  a.click()

  setTimeout(() => {
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }, 100)

  return true
}

export function getConditionFromSelectedValues(config, column, selectedValues, queryIndex) {
  if (!selectedValues?.length) return null

  if (config.renderType === EXPLORE_CARD_TYPES.LIST) {
    return getListCondition(config, column, selectedValues)
  }

  return getChartCondition(config, column, selectedValues, queryIndex)
}

function getListCondition(config, column, selectedValues) {
  const subConditions = []
  const hasEmptyValues = selectedValues.some((v) => v.value === null)
  if (hasEmptyValues) {
    subConditions.push({
      [column.id]: {
        IS_EMPTY: true
      }
    })
  }

  const nonEmptyValues = selectedValues.filter((item) => item.value !== null)

  if (nonEmptyValues.length) {
    const values = nonEmptyValues.map((item) => item.value)
    if (column.type === DATA_TYPES_MAP.NUMERIC) {
      const isRangeValues = nonEmptyValues.some((item) => item.min != item.max)
      if (isRangeValues) {
        const data = values.toSorted((a, b) => a - b)
        const range = getBoundaries(data, column.type, config.granularity.id)
        subConditions.push({
          AND: [{ [column.id]: { GTE: { VALUE: range.lower } } }, { [column.id]: { LT: { VALUE: range.upper } } }]
        })
      } else {
        subConditions.push({
          [column.id]: {
            IN_LIST: {
              VALUE: values
            }
          }
        })
      }
    } else if (column.type === DATA_TYPES_MAP.DATE) {
      const conditions = nonEmptyValues.map((item) => {
        return {
          [column.id]: {
            EQ: { VALUE: { TRUNCATE: config.granularity.id, VALUE: item.unformatted_value } }
          }
        }
      })
      subConditions.push({ OR: conditions })
    } else {
      subConditions.push({
        [column.id]: {
          IN_LIST: {
            VALUE: values
          }
        }
      })
    }
  }

  if (subConditions.length > 1) return { OR: subConditions }
  return subConditions[0]
}

function getChartCondition(config, column, selectedValues, queryIndex) {
  const values = selectedValues.map((v) => v.value)

  const subConditions = []

  let hasEmptyValues = values.includes(null)
  const chart = config.charts[queryIndex]
  const nonEmptyValues = values.filter((v) => v !== null)

  if (nonEmptyValues.length) {
    const range = getBoundaries(nonEmptyValues, column.type, chart.level)
    subConditions.push({
      AND: [{ [column.id]: { GTE: { VALUE: range.lower } } }, { [column.id]: { LT: { VALUE: range.upper } } }]
    })
  }

  if (hasEmptyValues) {
    subConditions.push({
      [column.id]: {
        IS_EMPTY: true
      }
    })
  }

  if (subConditions.length > 1) return { OR: subConditions }

  return subConditions[0]
}
