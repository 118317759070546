// Utils
import { convertNumberToAlphabet } from '@/utils/filters'
import { flattenObject, deepDecode } from '@/utils/string'

// Constants
import { STEP_TYPES, STEP_SUBTYPES, EXPORT_CONNECTORS, DATA_TYPES_MAP, NUMERIC_FORMAT } from '@/constants'

/**
 *
 * @param {*} data The data, coming from backend, that needs to be transformed into a frontend payload
 * @returns a payload suited to frontend's needs. The frontend relies on the returned result of this transformation
 */

export function transformUsers({ data }, workspaceId) {
  let tempUsers
  if (data.self) tempUsers = [data.self]
  else if (data.invites && data.users) tempUsers = [...data.users.users, ...data.invites]
  else if (data.users) tempUsers = data.users
  else if (data.active_users) tempUsers = data.active_users

  return tempUsers.map((u) => ({
    firstName: u.first_name,
    lastName: u.last_name,
    email: u.email,
    name: u.name || (u.first_name ? `${u.first_name} ${u.last_name}` : ''),
    id: u.id,
    roles: u.user_roles || (workspaceId && { workspaces: { [workspaceId]: u.role } }),
    lastTimeActive: u.last_active || u.last_active_date,
    isInvited: ['invited', 'expired'].includes(u.status),
    status: u.status,
    createdAt: u.created_at,
    profilePic: u.profile_link
  }))
}

export function transformWorkspaces(requestResponse) {
  const workspaces = requestResponse.data?.workspaces || [requestResponse.data?.workspace]
  return workspaces?.map((w) => ({
    id: w.id,
    name: w.name,
    subscription: w.subscription,
    status: w.status,
    userPermissions: w.user_permissions,
    projects: w.projects?.map((p) => ({
      id: p.id,
      name: p.name,
      subscribed: p.subscribed,
      updatedAt: p.updated_at,
      datasetQuantity: p.ds_count
    })),
    workspaceUsers: w.user_count
  }))
}

export function transformSmsDetails(response) {
  if (!response?.data) return

  const { data } = response

  const billingAddress = data.sms_details?.customer?.billing_address
  const subscription = data.sms_details?.subscription

  const transformPlan = (p) => ({
    id: p.id,
    name: p.name,
    description: p.description,
    monthlyPrice: p.price / 100,
    isSelfServe: p.cf_self_serve == 'Yes',
    startingUsersQuantity: p.cf_number_of_users,
    areNewUsersAllowed: p.cf_are_new_users_allowed == 'Yes',
    startingStorage: (p.cf_starting_row_count_in_millions || 0) * 1000000,
    isAdditionalStorageAllowed: p.cf_is_additional_storage_allowed == 'Yes',
    maxStorage: (p.cf_max_row_count_in_millions || 0) * 1000000,
    processingCapacity: p.cf_processing_capacity?.toLowerCase(),
    isCSVExcelImportEnabled: p.cf_import_csv_excel == 'Yes',
    isImportPublicDatasetsEnabled: p.cf_import_public_datasets == 'Yes',
    startingConnectorQuantity: p.cf_starting_connector_quantity,
    minimumPeriodicityForDataPulls:
      !p.cf_minimum_periodicity || p.cf_minimum_periodicity == 'None' ? '' : p.cf_minimum_periodicity.toLowerCase(),
    areAdditionalConnectorsAllowed: p.cf_are_additional_connectors_allowed == 'Yes',
    areAllTransformFeaturesEnabled: p.cf_all_transform_features == 'Yes',
    isCSVExcelExportEnabled: p.cf_export_csv_excel == 'Yes',
    isCreateDbEnabled: p.cf_create_db == 'Yes',
    isLiveLinkEnabled: p.cf_live_csv_link == 'Yes',
    isPrivateViewEnabled: p.cf_private_view == 'Yes',
    arePrivateViewersPerViewUnlimited: p.cf_private_view == 'Yes',
    isPublicViewEnabled: p.cf_public_view_access == 'Yes',
    isVersionControlEnabled: p.cf_version_control == 'Yes',
    areAuditReportsEnabled: p.cf_audit_reports == 'Yes',
    isDataAccessControlEnabled: p.cf_data_access_control == 'Yes',
    hasCustomerSuccessManager: p.cf_customer_success_manager == 'Yes',
    isWebhookEnabled: p.cf_webhooks == 'Yes',
    allowedConnectorQuantity:
      (p.cf_starting_connector_quantity || 0) +
      (transformAddons(subscription?.addons)?.additionalConnectors.quantity || 0),
    allowedUsersQuantity:
      (p.cf_number_of_users || 0) + (transformAddons(subscription?.addons)?.additionalUsers.quantity || 0)
  })

  const transformAddons = (addons) => {
    if (!addons) return

    const additionalUserAddon = addons.find((a) => a.id == 'additional-user')
    const additionalConnectorAddon = addons.find((a) => a.id == 'additional-data-connector')
    const additionalStorage = addons.find((a) => a.id == 'add-on-rows')

    return {
      additionalConnectors: {
        quantity: additionalConnectorAddon?.quantity || 0,
        price: additionalConnectorAddon?.price / 100
      },
      additionalUsers: { quantity: additionalUserAddon?.quantity || 0, price: additionalUserAddon?.price / 100 },
      additionalStorage: {
        quantity: additionalStorage?.quantity || 0,
        price: additionalStorage?.price / 100,
        storagePerUnit: additionalStorage?.meta_data?.unit_size_for_additional_rows
      }
    }
  }

  return {
    subscriptionId: subscription?.id,
    currentPlan: data.current_plan && transformPlan(data.current_plan),
    status: subscription?.status,
    trialEnd: subscription?.trial_end ? subscription.trial_end * 1000 : undefined,
    addons: subscription?.addons && transformAddons(subscription.addons),
    customerId: data.sms_details?.customer?.id,
    billing: {
      nextPaymentDate: subscription?.next_billing_at * 1000,
      maskedCardNumber: data.sms_details?.card?.masked_number,
      fullAddress: billingAddress
        ? `${billingAddress.line1} ${billingAddress.line2 || ''} ${billingAddress.city} ${billingAddress.country}`
        : ''
    },
    yearlyDiscount: data.available_coupons?.find((c) => c.id == 'YEARLYDISCOUNT')?.discount_percentage,
    availablePlans: data.available_plans?.map((p) => transformPlan(p)),
    availableAddons: transformAddons(data.available_addons)
  }
}

export function transformUsageInfo(info) {
  const { ROW_COUNT_UTILIZED, CURRENT_ROW_COUNT_ALLOWED, PLAN_ROW_COUNT_VALUE } = info?.data.workspace || {}

  return {
    workspace: {
      currentUsage: ROW_COUNT_UTILIZED,
      allowedUsage: CURRENT_ROW_COUNT_ALLOWED || PLAN_ROW_COUNT_VALUE
    }
  }
}

export function transformConnectors(response) {
  return response?.data?.integrations.map((connector) => ({
    key: connector.key,
    isPremium: !!connector.is_paid,
    isAcquired: !!connector.is_added
  }))
}

export function transformOnboardingFlags({ data }) {
  return flattenObject(data)
}

export function transformStep(step) {
  const transformedStep = {
    id: step.id,
    name: step.display_info?.name,
    note: step.display_info?.note || '',
    viewId: step.ws_id,
    status: step.status,
    type: step.handler_type ? STEP_TYPES.ACTION : STEP_TYPES.TASK,
    subType: transformStepSubType(step),
    dataSync: step.data_pass_through,
    rowCount: typeof step.row_count == 'number' ? step.row_count : step.desc?.row_count,
    reordered: step.reordered,
    runnable: step.runnable,
    sequence: step.sequence,
    subSequence: step.sub_sequence,
    outputSequence: getOutputSequence(step),
    isPinned: !!step.end_of_pipeline,
    params: transformStepParams(step.params),
    isInError: !!(step.error_info?.error_info?.ERROR_CODE || step?.reference_errors?.error_code),
    targetProperties: step.target_properties,
    lastModifiedTime: step.last_modified_time,
    connectorKey: EXPORT_CONNECTORS.find((c) => c.key == step.handler_type)?.key,
    displayInfo: {
      analysis: step.display_info?.analysis,
      hasNonUniqueValues: step.display_info?.has_non_unique_values,
      error: transformStepErrorInfo(step),
      metadata: transformMetadata(step.prev_metadata),
      url: step.last_run_result?.url
    },
    affectedColumnsIds: getAffectedColumnsIds(step)
  }

  transformedStep.supportsDataSync =
    transformedStep.type == STEP_TYPES.ACTION ||
    [STEP_SUBTYPES.JOIN, STEP_SUBTYPES.LOOKUP].includes(transformedStep.subType)
  return deepDecode(transformedStep)
}
export function transformStepErrorInfo(step) {
  return {
    code: step.error_info?.error_info?.ERROR_CODE || step?.reference_errors?.error_code,
    info:
      step.error_info?.error_info?.additional_info ||
      step.error_info?.additional_info ||
      step.reference_errors?.exception ||
      step.reference_errors?.reference_errors
  }
}
export function transformStepParams(params) {
  if (params?.COPY && params?.VERSION != 2) {
    const transformedParams = { ...params }
    transformedParams.COPY = [params.COPY]
    transformedParams.VERSION = 2
    delete transformedParams.CONDITION
    if (params.CONDITION) transformedParams.COPY[0].CONDITION = params.CONDITION
    return transformedParams
  }
  return params
}

export function transformStepSubType(step) {
  if (step.handler_type) {
    if (step.handler_type == 'internal_dataset')
      return step.target_properties.TRANSFORM ? STEP_SUBTYPES.CROSSTAB : STEP_SUBTYPES.BRANCH_OUT
    if (step.handler_type == 'branchout') return STEP_SUBTYPES.BRANCH_OUT
    if (step.handler_type == 's3') return STEP_SUBTYPES.LIVE_LINK
    if (step.handler_type == 'publishdb') return STEP_SUBTYPES.PUBLISH
    return STEP_SUBTYPES.EXPORT
  }

  if (step.task_type) return step.task_type

  if (step.params.SMALL || step.params.LARGE) {
    step.params[STEP_SUBTYPES.OBTAIN_LARGE_OR_SMALL] = step.params.SMALL || step.params.LARGE
    step.params[STEP_SUBTYPES.OBTAIN_LARGE_OR_SMALL].TYPE = step.params.SMALL ? 'small' : 'large'
    return STEP_SUBTYPES.OBTAIN_LARGE_OR_SMALL
  }
  if (step.params.REPLACE?.MAPPING) return STEP_SUBTYPES.BULK_REPLACE
  if (step.params.PIVOT && step.params._UI_CUSTOM == 'collapseRows') return STEP_SUBTYPES.CONCATENATE
  if (step.params.COPY) return STEP_SUBTYPES.BULK_COPY
  for (const subType of Object.values(STEP_SUBTYPES)) if (step.params[subType]) return subType

  return ''
}

export function transformMetadata(data) {
  if (!Array.isArray(data)) return []

  return data.filter(Boolean).map((column) => {
    try {
      const mappedColumn = {
        name: deepDecode(column.display_name),
        id: column.internal_name,
        order: column.order,
        type: DATA_TYPES_MAP[column.type]
      }

      if (typeof column.format == 'string' && column.type == DATA_TYPES_MAP.DATE)
        mappedColumn.format = { date_format: column.format }
      else if (column.format?.date_format) mappedColumn.format = column.format
      else if (column.format?.comma_separated) mappedColumn.format = transformNumericFormat(column.format)

      return mappedColumn
    } catch (error) {
      console.error('Error transforming column:', { column, error })
      return {
        name: deepDecode(column.display_name) || '',
        id: column.internal_name || '',
        order: column.order || '',
        type: column.type || '',
        format: ''
      }
    }
  })
}

function getOutputSequence(step) {
  if (step.sub_sequence) {
    if (step.sequence) return `${step.sequence}.${convertNumberToAlphabet(step.sub_sequence - 1)}.`
    return `${convertNumberToAlphabet(step.sub_sequence - 1)}.`
  }
  return `${step.sequence ?? convertNumberToAlphabet(0)}.`
}

function getAffectedColumnsIds(step) {
  if (step.handler_type) return []
  const params = step.params?.[transformStepSubType(step)]
  if (!params) return []

  const columns =
    params.SOURCE ||
    (Array.isArray(params) && params.map((c) => c.INTERNAL_NAME || c.SOURCE)) ||
    params.AS?.INTERNAL_NAME ||
    params.DESTINATION ||
    step?.params?.IGNORE_COLUMNS ||
    params.COLUMN
  if (!columns) return []
  return Array.isArray(columns) ? columns : [columns]
}

export function transformColumnsUsedInfo(columnsUsed) {
  if (!columnsUsed) return {}

  const transformedColumnsUsed = {}
  Object.entries(columnsUsed).forEach(([columnId, column]) => {
    transformedColumnsUsed[columnId] = transformMetadata([column])[0]
  })
  return transformedColumnsUsed
}

export function transformTakswiseInfo(data) {
  const sequenceWiseMetadata = {}
  const sequenceWiseColumnsUsed = {}
  Object.entries(data).forEach(([sequence, taskInfo]) => {
    sequenceWiseMetadata[sequence] = transformMetadata(taskInfo.metadata)
    sequenceWiseColumnsUsed[sequence] = transformColumnsUsedInfo(taskInfo.transform_params?.COLUMNS_USED)
  })
  return { sequenceWiseMetadata, sequenceWiseColumnsUsed }
}

export function transformNumericFormat(format) {
  const decimalPrecision = format?.decimalPrecision ?? format?.decimal_spec ?? NUMERIC_FORMAT.decimalPrecision
  return {
    shortForm: format?.shortForm || false,
    separator: format?.separator || format?.comma_separated || false,
    percentage: format?.percentage || format?.is_percentage || false,
    currency: format?.currency || format?.currency_symbol || '',
    decimalPrecision
  }
}
