import store from '@/store'

export default [
  {
    path: '/workspaces/:workspaceId/projects/:projectId/views/:viewId',
    name: 'DataEditor',
    component: () =>
      store.state.isInterfaceV1Active ? import('./pages/data-editor.vue') : import('./pages/data-editor-v2.vue')
  },
  {
    path: '/guest/workspaces/:workspaceId/projects/:projectId/views/:viewId',
    name: 'AnonymousDataEditor',
    component: () => import('./pages/anonymous-data-editor.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/workspaces/:workspaceId/projects/:projectId/dataviews/:viewId',
    redirect: '/workspaces/:workspaceId/projects/:projectId/views/:viewId' // Backward compatibility
  }
]
