<template>
  <div id="app">
    <v-app v-if="isInitializationComplete" :class="{ 'mm-interface-v1': $store.state.isInterfaceV1Active }">
      <router-view />
      <snoozed-workspace />
      <slow-network />
      <app-maintenance />
    </v-app>
    <mm-loader v-else class="initial-loader" size="xlarge" color="p700" />
  </div>
</template>

<script>
// Utils
import { fetchData } from '@/utils/fetch'
import { showErrorToast } from '@/utils/toasts'
import { initializeOnboarding } from '@/utils/onboarding'

// API
import authApi from '@/modules/auth/auth.api'

//Mixins
import DataUsageMixin from '@/mixins/dataUsage'

// Plugins
import { initializeSplitIo } from '@/plugins/splitio'

// Components
import SnoozedWorkspace from '@/modules/workspace/components/snoozed-workspace/snoozed-workspace.vue'
import SlowNetwork from '@/components/slow-network/slow-network.vue'
import AppMaintenance from '@/components/app-maintenance/app-maintenance.vue'

// Dependencies
import { mapGetters } from 'vuex'

export default {
  components: { SnoozedWorkspace, SlowNetwork, AppMaintenance },
  mixins: [DataUsageMixin],
  data: () => ({
    isInitializationComplete: false
  }),
  computed: {
    ...mapGetters(['isWorkspaceTrialExpired']),
    computedIsAuthenticated() {
      return this.$store.getters['auth/isUserAuthenticated']
    }
  },
  watch: {
    computedIsAuthenticated: {
      async handler(val) {
        try {
          if (val) {
            // IMPORTANT: This is a security endpoint that sets required cookies for authentication.
            // Please ensure this endpoint is called first, before any other APIs are initiated.
            await authApi.setSecurityCookie().catch((e) => {
              showErrorToast(e.data?.ERROR_CODE)
              setTimeout(() => this.$store.dispatch('auth/logout'), 5000)
            })

            if (this.$store.getters['auth/isUserSuperAdmin']) this.$router.push({ name: 'Admin' })
            else {
              initializeOnboarding()
              this.$store.dispatch('getCurrentUser')
              await fetchData()
              await this.$store.dispatch('getSmsDetails', { fetchAllDetails: true })
              setInterval(() => this.$store.dispatch('getSmsDetails'), 30000)
              await this.$store.dispatch('getWorkspaceUsage')
              this.mixinDataUsage_verifyDataUsageWarning()
              initializeSplitIo()
            }
          }
        } catch (e) {
          console.error('Error initializing app:', e)
        } finally {
          // Initialization should get complete if the user is not authenticated or if access errors were thrown so that the user can access pages that do not require authentication
          this.isInitializationComplete = true
        }
      },
      immediate: true
    },
    isWorkspaceTrialExpired: {
      handler(val) {
        if (val && !this.$route.name?.toLowerCase().includes('settings')) this.$router.push({ name: 'TrialExpired' })
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('online', () => this.$store.commit('setIsNetworkSlow', false))
    window.addEventListener('offline', () => this.$store.commit('setIsNetworkSlow', true))
  }
}
</script>

<style lang="scss">
#app {
  height: 100vh;
  overflow: hidden;

  .initial-loader {
    margin: auto;
    top: 50%;
  }
}
</style>
