import API from '@/api'

const apiGenerator = (path, errorMessage) => async (payload) => {
  try {
    const { data } = await API.post(path, payload, { customErrorHandling: true })
    return { data }
  } catch (response) {
    if (response.data?.ERROR_CODE) return { error: `global.api.${response.data.ERROR_CODE}` }
    if (response.status === 401 && errorMessage) return { error: errorMessage }
    return { error: response.error || 'global.api.generic_error' }
  }
}

const setSecurityCookie = async () => await API.post('set_sec', {})

const getKeycloak2fa = () => API.get('/keycloak-2fa')

const refreshToken = (refreshToken) => API.post('refresh_token', { refresh_token: refreshToken })

export default {
  login: apiGenerator('/login', 'auth.login.invalid_login'),
  getToken: apiGenerator('/token', ''),
  submitOtp: apiGenerator('/totp', 'auth.login.invalid_otp'),
  resetPassword: apiGenerator('/reset-password', ''),
  setUserData: apiGenerator('/change-password', 'auth.reset_password.invalid_link'),
  acceptWorkspaceInvite: apiGenerator('/accept-invite', ''),
  blacklistToken: apiGenerator('/blacklist_token', ''),
  modifyPassword: apiGenerator('/modify-password', ''),
  setSecurityCookie,
  getKeycloak2fa,
  setupKeycloak2fa: apiGenerator('/keycloak-2fa', ''),
  refreshToken
}
